import React, { useContext,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { ReservationContext } from './ReservationContext'; 
import { StepperContext } from './StepperContext';
import { Grid2 } from '@mui/material';
import Button from '@mui/material/Button';  
  
 
function EnterDetails() {
  const stepperContext = useContext(StepperContext); 
  const { handleNext } = stepperContext; 
  useEffect(() => {
    handleNext(1);  
    document.body.style.backgroundColor = '#78206a'; 
    return () => {
      document.body.style.backgroundColor = ''; 
    };
  }, []);
  const navigate = useNavigate();
  const { reservation, setReservation } = useContext(ReservationContext);


  if (!stepperContext) {
    console.error('StepperContext is undefined');
    return null;
  }

  
  const handleSubmit = () => { 
    navigate('/reservation-summary');
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setReservation({ ...reservation, [name]: value });
  };

  const handleBackb = () => { 
    navigate('/select-date');
  };

  return (
    <div>
       
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">*Nombre</label>
        <input
          type="text"
          id="name"
          name="name"
          value={reservation.name}
          onChange={handleChange}
          required
        />
        
        <label htmlFor="email">*Correo electrónico</label>
        <input
          type="email"
          id="email"
          name="email"
          value={reservation.email}
          onChange={handleChange}
          required
        />
        
        <label htmlFor="phone">*Número de contacto</label>
        <input
          type="number"
          id="phone"
          name="phone"
          value={reservation.phone}
          onChange={handleChange}
          required
        />
          
       
        <Grid2  container spacing={2} className='contentedor_botones'>
  <Grid2 size={6} >
  <Button color="success" variant="contained" type="button" onClick={handleBackb}>Anterior</Button>
  </Grid2>
  <Grid2 size={6}>
  <Button color="success" variant="contained" type="submit">Siguiente</Button>
  </Grid2>
   
</Grid2>
      </form>
    </div>
  );
}

export default EnterDetails;