import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import SelectDate from './SelectDate';
import EnterDetails from './EnterDetails';
import ReservationSummary from './ReservationSummary';
import FailedPayment from './FailedPayment';
import ApprovedPayment from './ApprovedPayment';
import { ReservationProvider } from './ReservationContext';
import { StepperProvider, StepperContext } from './StepperContext';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

function App() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const steps = ['Selecciona fecha', 'Ingresa tus datos', 'Resumen de reserva'];
  const stepsd = ['de disfrutar!', 'de disfrutar!', 'para disfrutar!'];
  const stepse = ['¡Estás a unos pasos ', '¡Estás a un paso ', '¡Último paso '];

  return (
    <ReservationProvider>
      <div className="headermain">
        <div className="container_logo">
          <img src="./logo.svg" className="logo" alt="Logo" />
        </div>
      </div>
      <StepperProvider>
        <Router>
          <div className="App">
            <Routes>
              <Route path="*" element={<MainApp steps={steps} stepsd={stepsd} stepse={stepse} activeStep={activeStep} />} />
            </Routes>
          </div>
        </Router>
      </StepperProvider>
    </ReservationProvider>
  );
}

function MainApp({ steps, stepsd, stepse, activeStep }) {
  const location = useLocation();
  const isFailedPaymentRoute = (location.pathname === '/pago-rechazado' || location.pathname === '/pago-aprobado' );

  return (
    <>
      <div className="header">
        {!isFailedPaymentRoute && (
          <StepperContext.Consumer>
            {({ activeStep }) => <h2 className="title_wh">{steps[activeStep]}</h2>}
          </StepperContext.Consumer>
        )} {isFailedPaymentRoute && ( 
           <p></p>
        )}
      </div>

      <div className="container">
        {!isFailedPaymentRoute && (
          <>
            <StepperContext.Consumer>
              {({ activeStep }) => (
                <p className={'colortxt color' + activeStep}>
                  <b>{stepse[activeStep]}</b>
                  {stepsd[activeStep]}
                </p>
              )}
            </StepperContext.Consumer>

            <StepperContext.Consumer>
              {({ activeStep }) => (
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel> </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              )}
            </StepperContext.Consumer>
          </>
        )}

        <Routes>
          <Route path="/select-date" element={<SelectDate />} />
          <Route path="/enter-details" element={<EnterDetails />} />
          <Route path="/reservation-summary" element={<ReservationSummary />} />
          <Route path="/pago-rechazado" element={<FailedPayment />} />
          <Route path="/pago-aprobado" element={<ApprovedPayment />} />
          <Route path="/" element={<SelectDate />} />
        </Routes>
      </div>
    </>
  );
}

export default App;