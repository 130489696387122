import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Función para extraer los parámetros de la URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function ApprovedPayment() {
  const [idOrden, setIdOrden] = useState(0);
  const [errorMsj, setErrorMsj] = useState('');
  const [orden, setOrden] = useState({ 
    id_orden: '',
    festejados: [],
    local: [],
    ord_fecreserva: '',
    hora: [],
    ord_festejados: 0,
    ord_invitados: 0,
    paquete: [],
    productos: [],
    ord_pagado: 0,
    ord_wpcodautorizacion: '',
    wp_hora_trans: '',
    ord_wptipocuotas: '',
    ord_wpnumcuotas: 0,
    ord_wpnumtarjeta: ''
  });

  const query = useQuery();

  useEffect(() => {
    const id_orden = query.get('id_orden');
     
    setIdOrden(id_orden); 

    // Simulando el llamado a la API para traer la orden
    const traerOrden = async (data) => {
      try {
        const response = await fetch('https://happyland-reservas.it-unit.cl/administrador/ordenes/traerOrden', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id_orden:data }),
        });
        console.log(JSON.stringify({ id_orden:data }));
        const res = await response.json();
        console.log(res);
        setOrden(res);
        return res;
      } catch (error) {
        console.error('Error fetching the order:', error);
      }
    };

    traerOrden(id_orden);
  }, [query]);

  return (
    <div>
      <h2 style={{
        textTransform: 'uppercase', 
        textAlign: 'center',
        color: '#db0032',
        fontSize: '1.7em',
        paddingTop: '19px',
        lineHeight: '1.2em',
        fontWeight: 'bold',
        fontFamily: 'Lato'
      }}>
        TU JUEGO  <br /> 
        YA ESTÁ ABONADO
      </h2>

      <h2 style={{ textAlign: 'center' }}>RESERVA N°{orden.id_orden}</h2>

      <div style={{
        paddingLeft: '20px',
        maxWidth: '300px',
        margin: '0 auto',
        backgroundPosition: '20px 0px',
        backgroundSize: '58px',
        backgroundRepeat: 'no-repeat'
      }}>
     

        <p className="resume_line">FECHA :<br />
          <span>{new Date(orden.ord_fecreserva).toLocaleDateString('es-CL', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</span>
        </p>
        <div className="sep"></div>

        <p className="resume_line">HORA:<br />
          <span>{orden.hora[0]}</span>
        </p>
        <div className="sep"></div>
 

        <p className="resume_line">NÚMERO PERSONAS:<br />
          <span>{orden.ord_invitados}</span>
        </p>
        <div className="sep"></div>
 
 
      </div>

      <div style={{
        paddingLeft: '20px',
        maxWidth: '300px',
        margin: '0 auto',
        backgroundPosition: '20px 0px',
        backgroundSize: '58px',
        backgroundRepeat: 'no-repeat'
      }}>
        <h2 style={{ textAlign: 'center' }}>DETALLE DE TRANSACCIÓN</h2>
        <p className="resume_line">MONTO PAGADO:<br />
          <span>{new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(orden.ord_pagado)}</span>
        </p>
        <div className="sep"></div>

        <p className="resume_line">CÓDIGO AUTORIZACIÓN:<br />
          <span>{orden.ord_wpcodautorizacion}</span>
        </p>
        <div className="sep"></div>

        <p className="resume_line">FECHA TRANSACCIÓN:<br />
          <span>{new Date(orden.wp_hora_trans).toLocaleDateString('es-CL')}</span>
        </p>
        <div className="sep"></div>

        <p className="resume_line">TIPO PAGO:<br />
          <span>{orden.ord_wptipocuotas}</span>
        </p>
        <div className="sep"></div>

        <p className="resume_line">NÚMERO CUOTAS:<br />
          <span>{orden.ord_wpnumcuotas}</span>
        </p>
        <div className="sep"></div>

        <p className="resume_line">NÚMERO TARJETA:<br />
          <span>{orden.ord_wpnumtarjeta}</span>
        </p>
        <div className="sep"></div>
      </div>
    </div>
  );
}

export default ApprovedPayment;