import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Función para extraer los parámetros de la URL
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function FailedPayment() {
  const [idOrden, setIdOrden] = useState(0);
  const [errorMsj, setErrorMsj] = useState('');
  const [orden, setOrden] = useState({ festejados: [] });
  
  const query = useQuery();

  useEffect(() => {
    const id_orden = query.get('id_orden');
    const error_msj = query.get('error_msj');
    setIdOrden(id_orden);
    setErrorMsj(decodeURIComponent(error_msj));

    // Simulando el llamado a la API para traer la orden
    const traerOrden = async (data) => {
        try {
          const response = await fetch('https://happyland-reservas.it-unit.cl/administrador/ordenes/traerorden', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id_orden:data }),
          });
      
          const res = await response.json();
          setOrden(res);
          return res;
        } catch (error) {
          console.error('Error fetching the order:', error);
        }
      };

    traerOrden(id_orden);
  }, [query]);

  const pagarWebpay = () => {
    const url = `https://happyland-reservas.it-unit.cl/administrador/reservar/pagar/${idOrden}`;
    window.location.href = url;
  };

  return (
    <div>
      <h2 style={{
        textTransform: 'uppercase', 
        textAlign: 'center',
        color: '#db0032',
        fontSize: '1.7em',
        paddingTop: '19px',
        lineHeight: '1.2em',
        fontWeight: 'bold',
        fontFamily: 'Lato'
      }}>
        OPS! LAMENTABLEMENTE<br /> NO SE REGISTRO EL PAGO
      </h2>

      <h3 style={{
        textTransform: 'uppercase',
        textAlign: 'center',
        color: '#db0032',
        fontSize: '1.1em',
        lineHeight: '1.1em',
        fontWeight: 'bold',
        fontFamily: 'Lato',
        maxWidth: '320px', 
        margin: '0 auto'
      }}>
        PERO AÚN TIENES RESERVADA LA PISTA POR 24 HRS
      </h3>

      <h2 style={{ textAlign: 'center', marginTop: '20px' }}>RESERVA N°{idOrden}</h2>

      <div style={{
        paddingLeft: '20px',
        maxWidth: '500px',
        margin: '0 auto',
        backgroundPosition: '20px 0px',
        backgroundSize: '58px',
        backgroundRepeat: 'no-repeat'
      }}>
        <p>
          Las posibles causas de este rechazo son:<br />
          * Error en el ingreso de los datos de su tarjeta de crédito o débito (fecha y/o código de seguridad).<br />
          * Su tarjeta de crédito o débito no cuenta con saldo suficiente.<br />
          * Tarjeta aún no habilitada en el sistema financiero.<br /><br />
        </p>

        <p className="resume_line">
          No se registró el pago del abono en el sistema.
        </p>
        <div className="sep"></div>

        <p className="resume_line">
          Si se descontó el saldo, Transbank reversará en un plazo máximo de 48 horas hábiles.
        </p>
        <div className="sep"></div>

        <p className="resume_line">
          También puedes pagar en el local directamente indicando el número de reserva en un plazo máximo de 48 horas.
        </p>
        <div className="sep"></div>

        <p className="resume_line">
          Posterior al plazo de 24 horas, se anula la reserva automáticamente.
        </p>
        <div className="sep"></div>

        <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
          <button onClick={pagarWebpay} style={{ backgroundColor: '#db0032', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '5px' }}>
            PAGAR RESERVA
          </button>
        </div>

        <p className="resume_line">
          Si tienes dudas revisa nuestras Preguntas Frecuentes o escríbenos AQUÍ.
        </p>

        <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
          <a style={{
            borderRadius: '20px',
            backgroundColor: '#007bff',
            color: '#fff',
            padding: '10px 20px',
            textDecoration: 'none'
          }} target="_blank" rel="noopener noreferrer" href="https://happylando.cl/contacto">
            CONTACTO
          </a>
        </div>
      </div>
    </div>
  );
}

export default FailedPayment;