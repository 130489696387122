import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select } from '@mui/material';
import { FormControl,MenuItem } from '@mui/material';
import { InputLabel } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { ReservationContext } from './ReservationContext';
import { StepperContext } from './StepperContext';

import Button from '@mui/material/Button';  
function SelectDate() {

  const navigate = useNavigate();
    const stepperContext = useContext(StepperContext);  

const { handleNext } = stepperContext; 
  const { reservation, setReservation } = useContext(ReservationContext);
  const [blockedDates, setBlockedDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);

  const fetchBlockedDates = (month, year) => {
    axios.get(`https://happyland-reservas.it-unit.cl/administrador/ordenes/bloqueosmes/1/${month}/${year}`)
      .then(response => {
        const blockedDays = response.data.map(date => dayjs(date));
        setBlockedDates(blockedDays);
      })
      .catch(error => {
        console.error('Error fetching blocked dates:', error);
      });
  };

  const fetchAvailableTimes = (date) => {
    const formattedDate = date.format('YYYY-MM-DD');
    axios.get(`https://happyland-reservas.it-unit.cl/administrador/ordenes/traerhorariolocal/1/?d=${formattedDate}`)
      .then(response => {
        const times = response.data.map(time => ({
          id: time.id_hr,
          hora: time.hora,
          cupos_disponibles: time.cupos_disponibles,
        }));
        setAvailableTimes(times);
      
      })
      .catch(error => {
        console.error('Error fetching available times:', error);
      });
  };

  useEffect(() => {
    handleNext(0);
    document.body.style.backgroundColor = '#f9006f';

    const today = dayjs();
  
    fetchBlockedDates(today.month() + 1, today.year()); // Cargar fechas bloqueadas del mes actual
      while (isDateBlocked(today)){
        today.add(1,'day');
      }
      handleDateChange(today);
    
    return () => {
      document.body.style.backgroundColor = '';  
    };
  }, []);

  if (!stepperContext) {
    console.error('StepperContext is undefined');
    return null;
  }
  
  const handleDateChange = (newDate) => {
    setReservation({ ...reservation, fecha: newDate, time:'',id_hr:'',cant:1,lanes:1 });
    fetchAvailableTimes(newDate); // Buscar horarios disponibles para la nueva fecha
  };

  const handleMonthChange = (newDate) => {
    fetchBlockedDates(newDate.month() + 1, newDate.year());
  };

  const handleTimeChange = (event) => {
    const selectedTime = availableTimes.find(time => time.id === event.target.value);
    setReservation({
      ...reservation,
      time: selectedTime.hora,
      id_hr: selectedTime.id,
      cant:1,
      lanes: selectedTime.cupos_disponibles, // Actualizar el número máximo de pistas según los cupos disponibles
    });
  };

  const handleLanesChange = (event) => {
    const cant = Math.min(event.target.value, reservation.lanes); // Asegurarse de no superar los cupos disponibles
    setReservation({ ...reservation, cant });
  };

  const isDateBlocked = (date) => {
    return blockedDates.some(blockedDate => blockedDate.isSame(date, 'day'));
  };

  return (
    <div  >
      
      <form onSubmit={() => navigate('/enter-details')}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DateCalendar
            value={reservation.fecha}
            onChange={handleDateChange}
            onMonthChange={handleMonthChange} // Actualiza las fechas bloqueadas cuando cambia el mes
            shouldDisableDate={isDateBlocked} // Deshabilitar fechas bloqueadas
          />
        </LocalizationProvider>
        <FormControl fullWidth>
        <InputLabel htmlFor="time">Bloque Horario</InputLabel>
        <Select id="time" name="time" value={reservation.id_hr} onChange={handleTimeChange} required>
          
          {availableTimes.map(time => (
            <MenuItem key={time.id} value={time.id}>
              {time.hora}  
            </MenuItem>
          ))}
        </Select></FormControl>
       
        <label className='leftp' htmlFor="lanes">Cantidad de Personas</label>
        <input
          type="number"
          id="lanes"
          name="lanes"
          min="1"
          max={reservation.lanes*4} // Limitar el número máximo de pistas a los cupos disponibles
          value={reservation.cant}
          onChange={handleLanesChange}
          required
        />
        
        <small className='leftp' > Máximo 4 Personas por pista. No aseguramos que las pistas reservadas sean continuas (queden juntas).</small>
        <p></p>
        <Button type="submit" color="success" variant="contained">Siguiente</Button>
        
      </form>
    </div>
  );
}

export default SelectDate;