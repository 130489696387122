import React, { createContext, useState } from 'react';

export const StepperContext = createContext();

export const StepperProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (p) => {
    setActiveStep(p);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <StepperContext.Provider value={{ activeStep, handleNext, handleBack, handleReset }}>
      {children}
    </StepperContext.Provider>
  );
};