import React, { useContext, useEffect, useState } from 'react';
import { ReservationContext } from './ReservationContext';
import { StepperContext } from './StepperContext';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';

// Función para obtener el precio del producto
const obtenerPrecioProducto = async () => {
  const response = await fetch('https://happyland-reservas.it-unit.cl/administrador/productos/local/1');
  const data = await response.json();
  return data[0]?.precio_prod || 0; // Devolver el precio del producto 1
};

function ReservationSummary() {
  const [precioProducto, setPrecioProducto] = useState(0);
  const [enviando, setEnviando] = useState(false);
  const stepperContext = useContext(StepperContext);
  const { handleNext } = stepperContext;
  const { reservation } = useContext(ReservationContext);

  // Obtener el precio del producto al montar el componente
  useEffect(() => {
    const obtenerPrecio = async () => {
      const precio = await obtenerPrecioProducto();
      setPrecioProducto(precio);
    };
    obtenerPrecio();

    handleNext(2);
    document.body.style.backgroundColor = '#284a97';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  const crearOrden = async (ordenc) => {
    const response = await fetch('https://happyland-reservas.it-unit.cl/administrador/ordenes/crearapi', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: ordenc }),
    });
    const res = await response.json();
    return res;
  };

  const pagarWebpay = async () => {
    if (!enviando) {
      setEnviando(true);

      // Crear la orden en el endpoint
      const ordenData = {
        nombre: reservation.name,
        email: reservation.email,
        phone: reservation.phone,
        fecha: reservation.fecha,
        id_hr: reservation.id_hr,
        cant: reservation.cant,
        pistas: Math.ceil(reservation.cant / 4),
        total: precioProducto * Math.ceil(reservation.cant / 4), // Total a pagar según las pistas
      };

      const res = await crearOrden(ordenData);

      // Redirigir a la URL de pago
      const url = `https://happyland-reservas.it-unit.cl/administrador/reservar/pagar/${res.id_orden}`;
      window.location.href = url;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    pagarWebpay(); // Llamar a la función pagar cuando se presiona el botón
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div>
            <p className={'colortxt color2'}><b>Datos de reserva</b></p>
            <p>
              Nombre: {reservation.name}<br />
              Correo electrónico: {reservation.email}<br />
              Número de contacto: {reservation.phone}<br />
              Fecha: {reservation.fecha?.format('DD MMMM YYYY')}<br />
              Horario: {reservation.time}<br />
              Cantidad de Personas: {reservation.cant}<br />
              Cantidad de Pistas: {Math.ceil(reservation.cant / 4)}
            </p>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className='calugaprecio'>
            <h3>Total a pagar</h3>
            <p>${precioProducto * Math.ceil(reservation.cant / 4)}</p>
          </div>
          <p className='aviso'>
            <span className='rosadotxt'>IMPORTANTE:</span><br />
            NO SE REALIZAN DEVOLUCIONES DE DINERO, SI NO SE PRESENTA EL DIA Y FECHA DE RESERVA.
          </p>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit}>
        <label>
          <input type="checkbox" name="terms" required /> Acepto términos y condiciones Bowling
        </label>
        <label>
          <input type="checkbox" name="offers" /> Acepto Info de Ofertas y Promociones
        </label><br />
        <Button type="submit" color="success" variant="contained">Pagar</Button>
      </form>
    </div>
  );
}

export default ReservationSummary;